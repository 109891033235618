<template>
  <button v-if="!link" :class="mode">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="mode" class="base-button">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    mode: {
      type: String,
      default: null,
      require: false,
    },
    link: {
      type: Boolean,
      default: false,
      required: false,
    },
    to: {
      type: String,
      default: "/",
      require: false,
    },
  },
};
</script>

<style scoped>
button{
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 3px;
  background-color: #222;
  color: #fff;
  font-size: 14px;
  transition: all 0.3s linear;
}
</style>