<template>
    <div class="main_loader " v-if="show">
        <div class="loader"></div>
    </div>
    <div class="" v-if="is_ok_sended">
        <slot name="add-ckeck">
            <i class="bi bi-check-lg check_send_icon_hide fs-3"></i>
        </slot>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        is_ok_sended: { 
            type: Boolean,
            required: true,
        }
    },
}
</script>


<style scoped>
.check_send_icon_hide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-height: 0; */
    height: 100%;
    border-radius: 3px;
    /* overflow: hidden; */
    transition: max-height ease-in-out;
    width: 100%;
    background-color: #39b826;
}



.main_loader {
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    background-color: #ff7e00d4;
    display: flex;
    justify-content: center; */
    display: flex;
    height: 100%;
    background-color: #ff8000;
    border-radius: 3px;

}

.loader {
    border: 4px solid #333;
    border-left-color: transparent;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    animation: spin89345 1s linear infinite;
    margin: auto;
}

@keyframes spin89345 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
