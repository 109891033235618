<template>
  <the-header></the-header>
  <router-view />
  <the-footer></the-footer>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue'
import TheFooter from './components/layout/TheFooter.vue'
import axios from 'axios';

export default {
  components: {
    TheHeader,
    TheFooter
  },
  data() {
    return {
      isLogin: true,
      username: '',
      email: '',
      password: '',
      repeatPassword: '',
      isAuthenticated: false,
      token: '',
      textToSave: '',
      savedTexts: []
    };
  },
  methods: {
    toggleForm() {
      this.isLogin = !this.isLogin;
    },
    async login() {
      try {
        const response = await axios.post('http://localhost:3000/api/login', {
          email: this.email,
          password: this.password,
          username: this.username,
        });
        this.token = response.data.token;
        this.username = response.data.username; // Ensure the username is retrieved from the response
        console.log(response.data)
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        this.isAuthenticated = true;
        localStorage.setItem('token', this.token);
        localStorage.setItem('username', this.username);
        alert(response.data.message);
        this.loadSavedTexts();
      } catch (error) {
        console.error('Login error:', error.response ? error.response.data : error.message);
        alert('Login failed');
      }
    },
    async register() {
      if (this.password !== this.repeatPassword) {
        alert("Passwords do not match");
        return;
      }

      try {
        const response = await axios.post('http://localhost:3000/api/register', {
          username: this.username,
          email: this.email,
          password: this.password
        });
        alert(response.data.message);
      } catch (error) {
        console.error('Register error:', error.response ? error.response.data : error.message);
        alert('Registration failed');
      }
    },
    async saveText() {
      try {
        const response = await axios.post('http://localhost:3000/api/save-text', {
          text: this.textToSave
        });
        alert(response.data.message);
        this.loadSavedTexts();
      } catch (error) {
        console.error('Save text error:', error.response ? error.response.data : error.message);
        alert('Failed to save text');
      }
    },
    async loadSavedTexts() {
      try {
        const response = await axios.get('http://localhost:3000/api/saved-texts');
        this.savedTexts = response.data;
      } catch (error) {
        console.error('Load saved texts error:', error.response ? error.response.data : error.message);
        alert('Failed to load saved texts');
      }
    },
    logout() {
      this.isAuthenticated = false;
      this.token = '';
      this.username = '';
      this.textToSave = '';
      this.savedTexts = [];
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      delete axios.defaults.headers.common['Authorization'];
    }
  },
  
}
</script>

<style>

li{
  list-style: none;
}
</style>
