import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import store from "../store/index"; // Import your Vuex store
import { useHead } from "@vueuse/head";

const routes = [
  {
    path: "/",
    redirect: "/",
    name: "home",
    component: HomePage,
    meta: { title: "Home Page" },
  },
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: { title: "Home Page" },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutUs.vue"),
    meta: { title: "About" },
  },
  {
    path: "/products?search=:title",
    name: "productPageWithPageTitle",
    component: () => import("../views/ProductPage.vue"),
    meta: { title: "Search" },
  },
  {
    path: "/products",
    name: "productPage",
    component: () => import("../views/ProductPage.vue"),
    meta: { title: "Products" },
  },
  {
    path: "/products/page/:id",
    name: "productPageWithPageId",
    props: true,
    component: () => import("../views/ProductPage.vue"),
  },
  {
    path: "/products/:id",
    name: "product-details",
    props: true,
    component: () => import("../views/ProductDetails.vue"),
    meta: {
      title: "Product Details",
    },
  },
  {
    path: "/contact",
    name: "ConatctUs",
    component: () => import("../views/ContactUs.vue"),
    meta: { title: "Contact" },
  },
  {
    path: "/wishlist",
    name: "wishlist",
    component: () => import("../views/WishlistPage.vue"),
    meta: { title: "wishlist" },
  },
  {
    path: "/cart",
    name: "UserCart",
    component: () => import("../views/UserCart.vue"),
    meta: { title: "Cart" },
  },

  {
    path: "/checkout",
    name: "checkout",
    component: () => import("../views/CheckOut.vue"),
    meta: { title: "Check out" },
  },
  {
    path: "/terms",
    name: " Terms&Conditions",
    component: () => import("../views/TermsConditions.vue"),
    meta: { title: "Terms & Conditions" },
  },
  {
    path: "/:notFound(.*)",
    name: "not-found",
    component: () => import("../views/NotFound.vue"),
    meta: { title: "Not Found" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { title } = to.meta;
  useHead({ title: title || "Page" });
  const pageNumber = to.params.id || 1;
  const sort = to.query.sort || "";
  const categories = to.query.categories
    ? decodeURIComponent(to.query.categories).split("+")
    : [];
  const brands = to.query.brands
    ? decodeURIComponent(to.query.brands).split("+")
    : [];

  if (to.query.search) {
    store.dispatch("product/getSearchProduct", to.query.search);
  } else {
    store.dispatch("product/actionProducts", {
      pageNumber,
      sort,
      filters: { categories, brands },
    });
  }
  // document.title = to.meta.title || "Default Title";
  next();
});

export default router;
