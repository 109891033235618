<template>
    <div class="loader">
        <div class="wrapper">
            <div class="line-1"></div>
        </div>
    </div>
</template>

<style scoped>
.loader {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: #fff;
    overflow: hidden;
}

.loader:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(110deg, rgba(227, 227, 227, 0) 0%, rgba(227, 227, 227, 0) 40%, rgba(227, 227, 227, 0.5) 50%, rgba(227, 227, 227, 0) 60%, rgba(227, 227, 227, 0) 100%);
    animation: gradient-animation_2 1.2s linear infinite;
}

.loader .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 3px;
}

.loader .wrapper>div {
    background-color: #eaeaea;
    border-radius: 3px;
}

.loader .line-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
}

@keyframes gradient-animation_2 {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}
</style>