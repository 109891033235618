<template>
    <!-- Start Footer -->
    <footer id="footer" class="main-footer pt-5 pb-5">
        <footer-mobile></footer-mobile>
        <div class="container">
            <div class="row">
                <!-- Box One -->
                <div class="footer-iteam footer-iteam-logo col-lg-4 col-md-4 col-12">
                    <aside id="block_36b84b73-e621-4021-83da-97979e6ac7e2" class="widget widget_text alo-logo-footer">
                   
                        <div class="header-logo hide-mobile col-md-3">
                            <a href="/" class="site-header__logo-image" >
                                <span class="fs-2 fw-blod">Logo</span>
                            </a>
                        </div>
                        <div class="hotline">
                            <p class="title">Hotline Free 24/24:</p>
                            <p class="phone-number">(+90) 553 *** ****</p>
                        </div>
                        <div class="text-content">
                            Add: 1234 Fatih Stress, Istanbul, Turkiye.<br />
                            Email: test@info.com
                        </div>
                    </aside>
                </div>
                <!-- Box Two -->
                <div class="footer-iteam d-block d-md-none col-lg-4 col-md-4 col-12">
                    <aside class="widget widget_nav_menu">
                        <h3 class="widget-title small" @click="is_height_val_1 = !is_height_val_1">
                            <span class="txt_title alo-color alo-text-transform">My Account</span><span
                                class="icon_mobile alo-color">
                                <i class="laber-icon-down bi bi-chevron-down"></i>
                                <i class="laber-icon-up icon-chevron-up"></i>
                            </span>
                        </h3>
                        <div class="menu_footer widget_footer" :class="is_height_val_1 ? 'menu_footer_height_0' : ''">
                            <ul class="menu p-0">
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Product Support
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Checkout </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Shopping Cart
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Wishlist </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Custom Link
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Redeem Voucher
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
                <!-- Box Three -->
                <div class="footer-iteam d-block d-md-none col-lg-4 col-md-4 col-12">
                    <aside class="widget widget_nav_menu">
                        <h3 class="widget-title small" @click="is_height_val_2 = !is_height_val_2">
                            <span class="txt_title alo-color alo-text-transform">Customer Service</span>
                            <span class="icon_mobile alo-color"><i class="laber-icon-down bi bi-chevron-down"></i><i
                                    class="laber-icon-up icon-chevron-up"></i></span>
                        </h3>
                        <div class="menu_footer widget_footer" :class="is_height_val_2 ? 'menu_footer_height_0' : ''">
                            <ul class="menu p-0">
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Help Center
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Contact Us
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Report Abuse
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Submit a Dispute
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Policies &amp; Rules
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Online Returns Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
                <!-- Box Two -->
                <div class="footer-iteam d-none d-md-block col-lg-4 col-md-4 col-12">
                    <aside class="widget widget_nav_menu">
                        <h3 class="widget-title small">
                            <span class="txt_title alo-color alo-text-transform">My Account</span>
                        </h3>
                        <div class="menu_footer widget_footer h-100">
                            <ul class="menu p-0">
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Product Support
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Checkout </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Shopping Cart
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Wishlist </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Custom Link
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Redeem Voucher
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
                <!-- Box Three -->
                <div class="footer-iteam d-none d-md-block col-lg-4 col-md-4 col-12">
                    <aside class="widget widget_nav_menu">
                        <h3 class="widget-title small">
                            <span class="txt_title alo-color alo-text-transform">Customer Service</span>

                        </h3>
                        <div class="menu_footer widget_footer h-100">
                            <ul class="menu p-0">
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Help Center
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Contact Us
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Report Abuse
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Submit a Dispute
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Policies &amp; Rules
                                    </a>
                                </li>
                                <li class="menu-item">
                                    <a class="alo-color alo-color-hover" href="#">Online Returns Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </footer>
    <!-- End Footer -->
</template>


<script>
import FooterMobile from './FooterMobile.vue';

import messageEn from '../../locales/en-US.json';
import messageAr from '../../locales/ar-AR.json';
import messageTr from '../../locales/tr-TR.json';
import EventBus from '../../event-bus'; // Import the event bus

export default {
    name: 'HomeView',
    components: { FooterMobile },
    data() {
        return {
            currentLanguage: localStorage.getItem('language') || 'en',
            messages: this.getMessages(localStorage.getItem('language') || 'en'),
            is_height_val_1: false,
            is_height_val_2: false,
        };
    },
    methods: {
        getMessages(language) {
            switch (language) {
                case 'en':
                    return messageEn;
                case 'ar':
                    return messageAr;
                case 'tr':
                    return messageTr;
                default:
                    return messageEn;
            }
        }
    },
    created() {
        // Listen for language change events
        EventBus.on('language-changed', (newLanguage) => {
            this.currentLanguage = newLanguage;
            this.messages = this.getMessages(newLanguage);
        });
    },
    beforeUnmount() {
        // Clean up the event listener
        EventBus.off('language-changed');
    }
};
</script>


<style scoped>
.payment_type_svg {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.payment_type_svg img {
    border-radius: 3px;
}

.icon_mobile {
    color: #fff;
}

.widget_nav_menu {
    transition: all linear 0.3s;
}



.menu_footer {
    height: 0px;
    transition: all linear 0.3s;
    overflow: hidden;
}

.menu_footer_height_0 {
    height: 200px;
}

#laber_footer-bottom {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #222e3c;
}

.small {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
    #laber_footer-bottom {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    #laber_footer1 {
        padding-top: 40px;
        padding-right: 0;
        padding-bottom: 40px;
        padding-left: 0;
    }
}

@media (max-width: 767px) {

    .main-footer aside.widget {
        border-radius: var(--alo-border-radius);
        margin-bottom: 5px;
        padding: 15px 20px;
        background: #ffffff0a;
        min-height: auto;
    }
}
</style>
