import axios from "axios";
export default {
  namespaced: true,
  state() {
    return {
      data:  [],
    };
  },
  mutations: {
    mutationProducts(state, payload) {
      state.data = payload;
      sessionStorage.setItem("productDataBrand", JSON.stringify(payload));
    },
    mutationDetailsProducts(state, payload) {
      state.data = payload;
      // sessionStorage.setItem("productData", JSON.stringify(payload));
    },
    mutationSearchProducts(state, payload) {
      state.data = payload;
      sessionStorage.setItem("productDataBrand", JSON.stringify(payload));
    },
  },
  actions: {
    async actionProducts(context) {
      let url =
        process.env.VUE_APP_API +
        `/brands?populate=*&pagination[page]=1&pagination[pageSize]=25`;

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
          },
        });
        const responseData = response.data;
 
        await context.commit("mutationProducts", responseData);
    
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
  },
  getters: {
    getProducts(state) {
      return state.data;
    },
  },
};
