import { createStore } from "vuex";

import product from "./modules/product.js";
import cart from "./modules/cart.js";
import category from "./modules/category.js";
import brand from "./modules/brand.js";
import wishlist from "./modules/wishlist.js";

export default createStore({
  modules: {
    product: product,
    cart: cart,
    category: category,
    brand: brand,
    wishlist: wishlist,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
