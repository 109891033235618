<template>
    <div>
        <li class="product-item d-flex ">
            <base-button link :to="productDetails" class=" thumb">
                <img class="lazyloaded me-3" width="80" loading="lazy"
                    :src="`${rootUrl}` + image[0].attributes.formats.thumbnail.url" alt="" />
            </base-button>


            <div class="info">
                <base-button class="product-name" link :to="productDetails">
                    <p>{{ desc }}</p>
                </base-button>
                <dl></dl>
                <div class="product-item-qty">
                    <div class="number price">
                        <span class="qty d-none">1x</span>
                        <span class="current_price">${{ price }}</span>
                    </div>
                 
                </div>
                <div class=" js-remove-item remove  pointer removeItem" @click="remove"><i class="bi bi-trash3"></i></div>
            </div>
        </li>
    </div>
</template>


<script>
import { Notyf } from 'notyf';

export default {
    data() {
        return {
            rootUrl: process.env.VUE_APP_URL

        }
    },
    props: ["id", "image", "old_price", "price", "qty", "brand", 'desc'],
    computed: {
        productDetails() {
            return `/product/${this.id}`;
        },
    },
    methods: {
        remove() {
            const notyf = new Notyf({
                position: {
                    x: 'right',
                    y: 'top',
                },
            });
            notyf.error('Remove From Wishlist');
            this.$store.dispatch("wishlist/removeFromWishlist", { id: this.id });
        },
    }
}

</script>