import axios from "axios";

export default {
  namespaced: true,
  state() {
    const wishlist = JSON.parse(sessionStorage.getItem("wishlist")) || [];
    return {
      data: JSON.parse(sessionStorage.getItem("productData")) || [],
      items: wishlist,
      qty: wishlist.reduce((sum, item) => sum + (item.qty || 0), 0),
      wishlistProduct: JSON.parse(sessionStorage.getItem("wishlist")) || [],
    };
  },
  mutations: {
    setWishlistProduct(state, product) {
        state.wishlistProduct = product;
        localStorage.setItem("wishlist", JSON.stringify(product));
      },
    mutationProducts(state, payload) {
      state.data = payload;
      console.log("mutationProducts state.data", state.data);
      sessionStorage.setItem("productData", JSON.stringify(payload));
    },
    removeProductFromWishlist(state, payload) {
      const prodId = payload;
      const productIndex = state.items.findIndex(
        (cartItem) => cartItem.productId === prodId
      );
      if (productIndex !== -1) {
        state.items.splice(productIndex, 1);
        state.qty = state.items.reduce((sum, item) => sum + (item.qty || 0), 0);
        sessionStorage.removeItem("wishlist", JSON.stringify(state.items));
      }
    },
    addProductToWishlist(state, payload) {
      const productData = payload;
      // Check if the product already exists in the wishlist
      const productInWishlist = state.items.find(
        (item) => item.id === productData.id
      );
      if (productInWishlist) {
        // If product already exists, do not add it again
        return;
      }
      const newItem = {
        productId: productData.id,
        brand: productData.attributes.brand,
        price: productData.attributes.price,
        old_price: productData.attributes.old_price,
        desc: productData.attributes.desc,
        image: productData.attributes.image,
        addedToWishlist: true, // Mark product as added to wishlist
        qty: 1,
      };
      state.items.push(newItem);
      state.qty++;
      sessionStorage.setItem("wishlist", JSON.stringify(state.items));
    },

    // removeProductFromWishlist(state, payload) {
    //   const prodId = payload;
    //   const productIndex = state.items.findIndex(
    //     (cartItem) => cartItem.id === prodId
    //   );
    //   if (productIndex !== -1) {
    //     state.items.splice(productIndex, 1);
    //     state.qty--;
    //     sessionStorage.setItem("wishlist", JSON.stringify(state.items));
    //   }
    // },
  },
  actions: {
    async actionProducts(context, foundProduct) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API +
            `/products?populate=*&filters[id][$eq]=${foundProduct}`,
          {
            headers: {
              Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
            },
          }
        );
        const responseData = response.data;
        console.log("response.data", responseData);
        await context.commit("mutationProducts", responseData);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          console.log(error.request);
          throw new Error("No response received");
        } else {
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    // async addToWishlist(context, payload) {
    //   const prodId = payload;
    //   const products = context.rootGetters["product/getProducts"];
    //   // await context.dispatch("actionProducts", prodId);
    //   console.log('wishlist.reduce((sum, item) => sum + (item.qty || 0)')
    //   try {
    //     await context.dispatch("actionProducts", prodId);

    //     // const products = context.getters.getProducts;

    //     console.log("products after dispatch", products);

    //     const product = products.data.find((prod) => prod.id == prodId);
    //     console.log("productproduct",product);

    //     if (product) {
    //       context.commit("addProductToWishlist", product);
    //     } else {
    //       console.error("Product not found in products data");
    //     }
    //   } catch (error) {
    //     console.error("Error adding product to cart:", error);
    //   }

    // },
  
    async addToWishlist(context, foundProducts) {
        console.log('fpindproduct' , foundProducts)
        const productIds = foundProducts.map((product) => product.id);
        // Create the query with the format filters[id][$in][0]=1&filters[id][$in][1]=2&...
        const filterQuery = productIds
          .map((id, index) => `filters[id][$in][${index}]=${id}`)
          .join("&");
        try {
          const response = await axios.get(
            process.env.VUE_APP_API +
              `/products?populate=*&${filterQuery}`,
            {
              headers: {
                Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
              },
            }
          );
          const responseData = response.data;
  
          await context.commit("setWishlistProduct", responseData);
        } catch (error) {
          // Handle errors here
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            throw new Error(error.response.data.message || "Network error");
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            throw new Error("No response received");
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            throw new Error(error.message || "Error setting up the request");
          }
        }
      },
    removeFromWishlist(context, payload) {
      context.commit("removeProductFromWishlist", payload);
    },
  },
  getters: {
    getProducts(state) {
      return state.data;
    },
    getWishlist(state) {
      return state.wishlistProduct;
    },
    // quantity(state) {
    //   return state.qty;
    // },
  },
};
