<template>
    <div class="type_shipping ">
        <section class="horizontal mb-5">
            <div class="container">
                <div class="alo-shipping pt-4 pb-4">
                    <div class="">
                        <!-- One -->
                        <div class="item item_0 col-sm-6 col-md-6 col-lg-3">
                            <div class="alo-benefit flex-nowrap d-flex justify-content-evenly align-items-center">
                                <div class="icon icon_svg">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="42" height="42"
                                        viewBox="0 0 20 20" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <path fill="#000000"
                                            d="M13.5 9c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5zM13.5 5c-0.827 0-1.5 0.673-1.5 1.5s0.673 1.5 1.5 1.5 1.5-0.673 1.5-1.5-0.673-1.5-1.5-1.5z">
                                        </path>
                                        <path fill="#000000"
                                            d="M0.5 20c-0.13 0-0.258-0.051-0.354-0.146-0.137-0.137-0.183-0.342-0.116-0.524 1.539-4.231 3.157-6.377 4.808-6.377 0.545 0 1.054 0.234 1.515 0.694 0.728 0.728 0.754 1.453 0.648 1.933-0.334 1.508-2.464 2.985-6.33 4.391-0.056 0.020-0.114 0.030-0.171 0.030zM4.839 13.952c-0.495 0-1.078 0.434-1.685 1.255-0.597 0.807-1.197 1.957-1.788 3.427 1.335-0.537 2.408-1.082 3.199-1.625 1.067-0.733 1.383-1.3 1.46-1.646s-0.047-0.677-0.379-1.009c-0.27-0.27-0.534-0.401-0.808-0.401z">
                                        </path>
                                        <path fill="#000000"
                                            d="M19.5 0c-2.595 0-4.907 0.44-6.874 1.308-1.621 0.716-3.009 1.72-4.126 2.986-0.208 0.236-0.401 0.475-0.58 0.715-0.862 0.057-1.744 0.4-2.624 1.020-0.745 0.524-1.493 1.25-2.225 2.157-1.23 1.524-1.986 3.027-2.018 3.090-0.109 0.219-0.044 0.485 0.154 0.628 0.088 0.064 0.191 0.095 0.293 0.095 0.128 0 0.256-0.049 0.353-0.145 0.005-0.005 0.483-0.475 1.298-0.936 0.672-0.38 1.708-0.827 2.961-0.906 0.317 0.811 1.224 1.781 1.659 2.216s1.405 1.342 2.216 1.659c-0.079 1.253-0.526 2.289-0.906 2.961-0.462 0.816-0.931 1.293-0.935 1.297-0.173 0.173-0.195 0.447-0.052 0.646 0.097 0.134 0.249 0.208 0.406 0.208 0.076 0 0.152-0.017 0.223-0.053 0.063-0.032 1.566-0.788 3.090-2.018 0.907-0.732 1.633-1.48 2.157-2.225 0.62-0.881 0.962-1.762 1.020-2.624 0.239-0.178 0.478-0.371 0.715-0.58 1.266-1.117 2.27-2.505 2.986-4.126 0.868-1.966 1.308-4.279 1.308-6.874v-0.5h-0.5zM3.107 9.808c0.953-1.383 2.41-3.098 4.052-3.637-0.649 1.137-0.985 2.181-1.106 2.843-1.159 0.071-2.158 0.408-2.946 0.794zM10.193 16.89c0.386-0.788 0.723-1.786 0.793-2.944 0.661-0.121 1.706-0.457 2.842-1.105-0.54 1.639-2.254 3.096-3.636 4.049zM15.044 10.75c-2.080 1.835-4.234 2.25-4.544 2.25-0.002 0-0.207-0.004-0.759-0.393-0.385-0.271-0.834-0.657-1.262-1.085s-0.814-0.877-1.085-1.262c-0.389-0.552-0.393-0.757-0.393-0.759 0-0.31 0.415-2.464 2.25-4.544 2.196-2.488 5.557-3.849 9.744-3.95-0.101 4.187-1.461 7.548-3.95 9.744z">
                                        </path>
                                    </svg>
                                </div>
                                <div class="">
                                    <h3 class="title alo-color">Free Shipping</h3>
                                    <p class="alo-color">Free Shipping On All Order</p>
                                </div>
                            </div>
                        </div>
                        <!-- Two -->
                        <div class="item item_1 col-sm-6 col-md-6 col-lg-3">
                            <div class="alo-benefit flex-wrap d-flex justify-content-evenly align-items-center"
                                style="--color-icon: #333e48">
                                <div class="icon icon_svg">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="42" height="42"
                                        viewBox="0 0 20 20" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <path fill="#000000"
                                            d="M19.854 8.646c-0.195-0.195-0.512-0.195-0.707 0l-1.149 1.149c-0.051-2.060-0.878-3.99-2.341-5.452-1.511-1.511-3.52-2.343-5.657-2.343-2.974 0-5.686 1.635-7.077 4.266-0.129 0.244-0.036 0.547 0.208 0.676s0.547 0.036 0.676-0.208c1.217-2.303 3.59-3.734 6.193-3.734 3.789 0 6.885 3.027 6.997 6.789l-1.143-1.143c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l2 2c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l2-2c0.195-0.195 0.195-0.512 0-0.707z">
                                        </path>
                                        <path fill="#000000"
                                            d="M16.869 13.058c-0.244-0.129-0.547-0.036-0.676 0.208-1.217 2.303-3.59 3.734-6.193 3.734-3.789 0-6.885-3.027-6.997-6.789l1.143 1.143c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-2-2c-0.195-0.195-0.512-0.195-0.707 0l-2 2c-0.195 0.195-0.195 0.512 0 0.707s0.512 0.195 0.707 0l1.149-1.149c0.051 2.060 0.878 3.99 2.341 5.452 1.511 1.511 3.52 2.343 5.657 2.343 2.974 0 5.686-1.635 7.077-4.266 0.129-0.244 0.036-0.547-0.208-0.676z">
                                        </path>
                                    </svg>
                                </div>
                                <div class="">
                                    <h3 class="title alo-color" style="--alo-color: #333e48">
                                        Money Guarantee
                                    </h3>
                                    <p class="alo-color" style="--alo-color: #656d74">
                                        30 Day Money Back Guarantee
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Three -->
                        <div class="item item_2 col-sm-6 col-md-6 col-lg-3">
                            <div class="alo-benefit flex-wrap d-flex justify-content-evenly align-items-center"
                                style="--color-icon: #333e48">
                                <div class="icon icon_svg">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="42" height="42"
                                        viewBox="0 0 20 20" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <path fill="#000000"
                                            d="M18.5 18h-11c-0.827 0-1.5-0.673-1.5-1.5 0-0.048 0.011-1.19 0.924-2.315 0.525-0.646 1.241-1.158 2.128-1.522 1.071-0.44 2.4-0.662 3.948-0.662s2.876 0.223 3.948 0.662c0.887 0.364 1.603 0.876 2.128 1.522 0.914 1.125 0.924 2.267 0.924 2.315 0 0.827-0.673 1.5-1.5 1.5zM7 16.503c0.001 0.275 0.225 0.497 0.5 0.497h11c0.275 0 0.499-0.223 0.5-0.497-0.001-0.035-0.032-0.895-0.739-1.734-0.974-1.157-2.793-1.768-5.261-1.768s-4.287 0.612-5.261 1.768c-0.707 0.84-0.738 1.699-0.739 1.734z">
                                        </path>
                                        <path fill="#000000"
                                            d="M13 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4c0 2.206-1.794 4-4 4zM13 4c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z">
                                        </path>
                                        <path fill="#000000"
                                            d="M4.5 18h-3c-0.827 0-1.5-0.673-1.5-1.5 0-0.037 0.008-0.927 0.663-1.8 0.378-0.505 0.894-0.904 1.533-1.188 0.764-0.34 1.708-0.512 2.805-0.512 0.179 0 0.356 0.005 0.527 0.014 0.276 0.015 0.487 0.25 0.473 0.526s-0.25 0.488-0.526 0.473c-0.153-0.008-0.312-0.012-0.473-0.012-3.894 0-3.997 2.379-4 2.503 0.001 0.274 0.225 0.497 0.5 0.497h3c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                                        </path>
                                        <path fill="#000000"
                                            d="M5 12c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zM5 7c-1.103 0-2 0.897-2 2s0.897 2 2 2 2-0.897 2-2c0-1.103-0.897-2-2-2z">
                                        </path>
                                    </svg>
                                </div>
                                <div class="">
                                    <h3 class="title alo-color" style="--alo-color: #333e48">
                                        Online Support 24/7
                                    </h3>
                                    <p class="alo-color" style="--alo-color: #656d74">
                                        Technical Support 24/7
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Four -->
                        <div class="item item_3 col-sm-6 col-md-6 col-lg-3">
                            <div class="alo-benefit flex-nowrap d-flex justify-content-evenly align-items-center"
                                style="--color-icon: #333e48">
                                <div class="icon icon_svg">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="42" height="42"
                                        viewBox="0 0 20 20" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <path fill="#000000"
                                            d="M18.5 18h-17c-0.827 0-1.5-0.673-1.5-1.5v-13c0-0.827 0.673-1.5 1.5-1.5h17c0.827 0 1.5 0.673 1.5 1.5v13c0 0.827-0.673 1.5-1.5 1.5zM1.5 3c-0.276 0-0.5 0.224-0.5 0.5v13c0 0.276 0.224 0.5 0.5 0.5h17c0.276 0 0.5-0.224 0.5-0.5v-13c0-0.276-0.224-0.5-0.5-0.5h-17z">
                                        </path>
                                        <path fill="#000000"
                                            d="M9.5 6h-6c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h6c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                                        </path>
                                        <path fill="#000000"
                                            d="M9.5 9h-6c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h6c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                                        </path>
                                        <path fill="#000000"
                                            d="M9.5 11h-6c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h6c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                                        </path>
                                        <path fill="#000000"
                                            d="M9.5 13h-6c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h6c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                                        </path>
                                        <path fill="#000000"
                                            d="M8.5 15h-5c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h5c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                                        </path>
                                        <path fill="#000000"
                                            d="M17.943 6.544l-0.813-0.591-0.311-0.956h-1.005l-0.813-0.591-0.813 0.591h-1.005l-0.311 0.956-0.813 0.591 0.311 0.956-0.311 0.956 0.813 0.591 0.132 0.406c-0.002 0.016-0.002 0.031-0.002 0.047v5c0 0.202 0.122 0.385 0.309 0.462s0.402 0.035 0.545-0.108l1.146-1.146 1.146 1.146c0.096 0.096 0.223 0.147 0.354 0.146 0.064 0 0.129-0.012 0.191-0.038 0.187-0.077 0.309-0.26 0.309-0.462v-5c0-0.016-0.001-0.032-0.002-0.047l0.132-0.406 0.813-0.591-0.311-0.956 0.311-0.956zM13.233 6.926l0.488-0.355 0.187-0.574h0.604l0.488-0.355 0.488 0.355h0.604l0.187 0.574 0.488 0.355-0.187 0.574 0.187 0.574-0.488 0.355-0.187 0.574h-0.604l-0.488 0.355-0.488-0.355h-0.604l-0.187-0.574-0.488-0.355 0.187-0.574-0.187-0.574zM15.354 12.646c-0.195-0.195-0.512-0.195-0.707 0l-0.646 0.646v-3.29h0.187l0.813 0.591 0.813-0.591h0.187v3.29l-0.646-0.646z">
                                        </path>
                                    </svg>
                                </div>
                                <div class="">
                                    <h3 class="title alo-color" style="--alo-color: #333e48">
                                        Member Discount
                                    </h3>
                                    <p class="alo-color" style="--alo-color: #656d74">
                                        Upto 40% Discount All Products
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style scoped>
.alo-shipping>div {
    display: flex;
    flex-wrap: wrap;
}

.item {
    margin-bottom: 20px;
}
</style>