<template>
    <div>
        <li class="product-item d-flex ">
            <div @click="productDetails" class=" thumb cursor-pointer">
                <img class="lazyloaded me-3" width="80" loading="lazy" :src="image[0].attributes.formats.thumbnail.url"
                    alt="" />
            </div>


            <div class="info">
                <div class="product-name cursor-pointer" @click="productDetails">
                    <p>{{ brand }}</p>
                    <p>{{ desc }}</p>
                </div>
                <div class="product-item-qty">
                    <div class="number price">
                        <span class="qty d-none">1x</span>
                        <span class="current_price">${{ price }}</span>
                    </div>
                    <div class="quantity-item">
                        <div class="quantity-cart">
                            <div class="group-quantity-button js-quantity">
                                <div class="minus js_minus js_plus_minus pointer" @click="removeQty">
                                    <i class="bi bi-dash close_prop">
                                    </i>
                                </div>
                                <div class="relative">
                                    <input class="input-text qty js_qty" type="text" size="4" title="Qty" :value="qty"
                                        readonly>
                                    <baseqty-spinner class="spinner_qty" v-if="isLoading"></baseqty-spinner>
                                </div>
                                <div class="plus js_plus js_plus_minus pointer" @click="addToCart">
                                    <i class="bi bi-plus close_prop">
                                    </i>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class=" js-remove-item remove  pointer removeItem"
                    :style="currentLanguage === 'ar' ? { left: '0px' } : { right: '0px' }" @click="remove"><i
                        class="bi bi-trash3"></i></div>
            </div>
        </li>
    </div>
</template>


<script>

import messageEn from '../../locales/en-US.json';
import messageAr from '../../locales/ar-AR.json';
import messageTr from '../../locales/tr-TR.json';
import EventBus from '../../event-bus'; // Import the event bus

export default {
    data() {
        return {
            currentLanguage: localStorage.getItem('language') || 'en',
            messages: this.getMessages(localStorage.getItem('language') || 'en'),
            rootUrl: process.env.VUE_APP_URL,
            isLoading: false,

        }
    },
    props: ["id", "image", "old_price", "price", "qty", "brand", 'desc'],
    // computed: {
    //     productDetails() {
    //         this.$store.dispatch('product/getQueryDetailsProduct', this.id);
    //         this.$router.push(`/products/${this.id}`)
    //                 },
    // },
    methods: {
        productDetails() {
            this.$store.dispatch('product/getQueryDetailsProduct', this.id);
            this.$router.push(`/products/${this.id}`);
        },
        getMessages(language) {
            switch (language) {
                case 'en':
                    return messageEn;
                case 'ar':
                    return messageAr;
                case 'tr':
                    return messageTr;
                default:
                    return messageEn;
            }
        },
        remove() {

            this.$store.dispatch("cart/removeFromCart", this.id);
        },
        async addToCart() {
            this.isLoading = true
            await this.$store.dispatch("cart/addToCart", { id: this.id, qty: 1 });
            this.isLoading = false

        },
        removeQty() {

            this.$store.dispatch("cart/removeOneFromCart", this.id);
        },
    },
    created() {
        // Listen for language change events
        EventBus.on('language-changed', (newLanguage) => {
            this.currentLanguage = newLanguage;
            this.messages = this.getMessages(newLanguage);
        });
    },
    beforeUnmount() {
        // Clean up the event listener
        EventBus.off('language-changed');
    }
}

</script>