<template>
  <div>
    <base-spinner v-if="isLoading"></base-spinner>

    <home-swip></home-swip>
    <div class="main-content-offers pt-5 pb-5">
      <div class="container">
        <div class="new_products">
          <h2 class="">Offers</h2>
          <!-- <div class=" main-box ">
            <swip-newporduct></swip-newporduct>
          </div> -->
          <div class=" main-box ">
            <swiper :slidesPerView="6" :spaceBetween="30" :freeMode="true" :pagination="{
              clickable: true,
            }" :loop="canLoop" :autoplay="{
      delay: 2000,
      disableOnInteraction: false,
    }" :speed="500" :breakpoints="{
      '340': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      '1024': {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    }" :modules="modules" class="swiper">
              <swiper-slide v-for="product in getOfferProduct.data" :key="product.id">
                <product-card :id="product.id" :brand="product.attributes.brand.data?.attributes?.title || ''"
                  :price="product.attributes.price" :image="product.attributes.image.data"
                  :desc="product.attributes?.desc || ''" :ram="product.attributes.ram?.data?.attributes?.title || ''"
                  :ssd_capacity="product.attributes.ssd_capacity?.data?.attributes?.title || ''"
                  :display_card="product.attributes.display_card?.data?.attributes?.title || ''"
                  :old_price="product.attributes.old_price" :quantity="product.attributes.quantity"
                  :is_new="product.attributes.is_new" :addedToWishlist="product.addedToWishlist"></product-card>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="new_products">
          <h2>New Products</h2>
          <div class=" main-box ">
            <swiper :slidesPerView="6" :spaceBetween="30" :freeMode="true" :pagination="{
              clickable: true,
            }" :loop="canLoop" :speed="500" :breakpoints="{
      '340': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      '1024': {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    }" :modules="modules" class="swiper position-relative">
              <swiper-slide v-for="product in getNewProduct.data" :key="product.id">
                <product-card :id="product.id" :brand="product.attributes.brand.data?.attributes?.title || ''"
                  :price="product.attributes.price" :image="product.attributes.image.data"
                  :desc="product.attributes?.desc || ''" :ram="product.attributes.ram?.data?.attributes?.title || ''"
                  :ssd_capacity="product.attributes.ssd_capacity?.data?.attributes?.title || ''"
                  :display_card="product.attributes.display_card?.data?.attributes?.title || ''"
                  :old_price="product.attributes.old_price" :quantity="product.attributes.quantity"
                  :is_new="product.attributes.is_new" :addedToWishlist="product.addedToWishlist"></product-card>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="last_vis">
          <h2>Last Visit</h2>
          <div>
            <swiper :slidesPerView="6" :spaceBetween="30" :freeMode="true" :pagination="{
              clickable: true,
            }" :loop="canLoop" :speed="500" :breakpoints="{
      '340': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      '1024': {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    }" :modules="modules" class="swiper position-relative">
              <swiper-slide v-for="product in lastVisitedProduct.data" :key="product.id">
                <product-card :id="product.id" :brand="product.attributes.brand.data?.attributes?.title || ''"
                  :price="product.attributes.price" :image="product.attributes.image.data"
                  :desc="product.attributes?.desc || ''" :ram="product.attributes.ram?.data?.attributes?.title || ''"
                  :ssd_capacity="product.attributes.ssd_capacity?.data?.attributes?.title || ''"
                  :display_card="product.attributes.display_card?.data?.attributes?.title || ''"
                  :old_price="product.attributes.old_price" :quantity="product.attributes.quantity"
                  :is_new="product.attributes.is_new" :addedToWishlist="product.addedToWishlist"></product-card>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>

      <more-secions></more-secions>

    </div>
  </div>
</template>

<script>

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';

import { FreeMode, Pagination } from 'swiper/modules';


import messageEn from '../locales/en-US.json';
import messageAr from '../locales/ar-AR.json';
import messageTr from '../locales/tr-TR.json';
import EventBus from '../event-bus'; // Import the event bus



import MoreSecions from '../components/MoreSecions.vue';
import HomeSwip from '../components/layout/HomeSwip.vue';
// import SwipNewporduct from '../components/layout/SwipNewporduct.vue'
export default {
  name: 'HomeView',
  components: {
    HomeSwip,
    MoreSecions,
    Swiper,
    SwiperSlide,

  },
  data() {
    return {
      isLoading: false,
      modules: [FreeMode, Pagination],
      searchQueryProduct: true,
      currentLanguage: localStorage.getItem('language') || 'en',
      messages: this.getMessages(localStorage.getItem('language') || 'en'),
    };
  },
  computed: {
    getNewProduct() {
      return this.$store.getters["product/newProducts"];
    },
    getOfferProduct() {
      return this.$store.getters["product/offerProducts"];
    },
    lastVisitedProduct() {
      return this.$store.getters['product/getLastVisitedProduct'];
    },
  },
  methods: {
    async loadNewProduct() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("product/loadNewProduct");
        this.isLoading = false;
      } catch (error) {
        console.error("Error loading products:", error);
        this.isLoading = false;
      }
    },
    async loadOfferProduct() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("product/offerDataProducts");
        this.isLoading = false;
      } catch (error) {
        console.error("Error loading products:", error);
        this.isLoading = false;
      }
    },
    async getLastVisitedProduct() {
      this.isLoading = true;
      const lastVisited = JSON.parse(localStorage.getItem('lastVisitedProducts'))
      try {
        await this.$store.dispatch("product/actionLastVisitedProduct", lastVisited);
        this.isLoading = false;
      } catch (error) {
        console.error("Error loading products:", error);
        this.isLoading = false;
      }
    },

    getMessages(language) {
      switch (language) {
        case 'en':
          return messageEn;
        case 'ar':
          return messageAr;
        case 'tr':
          return messageTr;
        default:
          return messageEn;
      }
    },

  },
  mounted() {
    this.loadNewProduct();
    this.loadOfferProduct();
    this.getLastVisitedProduct();
  },
  created() {
    // Listen for language change events
    EventBus.on('language-changed', (newLanguage) => {
      this.currentLanguage = newLanguage;
      this.messages = this.getMessages(newLanguage);
    });
  },
  beforeUnmount() {
    // Clean up the event listener
    EventBus.off('language-changed');
  }
};
</script>


<style scoped>
* {
  /* transition: all 0.3s linear; */
}

.not-found h2 {
  color: #5d657b;
}

.not-found img {
  width: 500px;
}

h2 {
  margin-bottom: 30px;
}

.main-content-offers {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.selected {
  background-color: #e0e0e0;
}

.main-box {
  /* display: grid; */
  grid-template-columns: repeat(auto-fill, minmax(199px, 1fr));
  margin: auto;
  gap: 15px;
}



@media (max-width: 577px) {
  .main-box {
    /* grid-template-columns: repeat(auto-fill, minmax(168px, 1fr)); */

  }

  .offers-products li {
    font-size: 10px;
  }
}

@media (max-width: 370px) {

  .main-box {
    /* grid-template-columns: repeat(auto-fill, minmax(160px, 1fr)); */
  }
}
</style>