<template>
    <!-- Start Product Cart -->
    <div class="productLazyload mb-5">
        <div class="product-collection">
            <!-- Box One -->
            <div class="product-item-image relative">
                <div class="card__media">
                    <div class="media">
                        <base-button link :to="getProductDetails" class="d-block image_product"
                            @mouseover="hovered = true" @mouseleave="hovered = false">
                            <!-- images -->
                            <img class="motion-reduce" loading="lazy" :src="this.image[0].attributes.formats.small.url"
                                alt="" draggable="false" />
                            <img class="motion-reduce absolute" loading="lazy"
                                :src="`${this.image[1].attributes.formats.small.url}`" alt="" draggable="false" />
                        </base-button>
                    </div>
                </div>
                <div class="product-image__overlay-top-left">
                    <div class="label label--sale" v-if="newPrice ? newPrice : ''">
                        -{{ newPrice }}%
                    </div>
                    <!-- <span class="product-type"> {{ is_featured ? "New" : "" }}</span> -->
                    <div class="d-none-important label label--new" v-if="is_new">
                        {{ messages.messages.shop.new }}
                    </div>
                </div>
                <div class="product-image__overlay-bottom absolute">
                    <div class="group-button">
                        <div class="inner d-flex flex-column gap-1">
                            <button @click="addProductToWishlist" v-if="!addedClass"
                                class="wishlist-button wishlist btn--status js-store-lists-add-wishlist js-wishlist">
                                <i class="bi bi-heart"></i>
                                <span class="text absolute">{{ messages.messages.shop.wishlistAdd }}</span>
                            </button>
                            <button @click="removeProductFromWishlist"
                                class="wishlist-button wishlist btn--status js-store-lists-add-wishlist js-wishlist"
                                v-if='addedClass'>
                                <i class="bi bi-check-lg"></i>
                            </button>
                            <button @click="addToCompare" v-if="!addedClassCompare"
                                class="compare-button btn--status js-store-lists-add-compare js-compare">
                                <i class="bi bi-stack"></i>
                                <span class="text absolute">{{ messages.messages.shop.addCompare }}</span>
                            </button>
                            <button @click="removeProductCompare"
                                class="wishlist-button wishlist btn--status js-store-lists-add-wishlist js-wishlist"
                                v-if='addedClassCompare'>
                                <i class="bi bi-check-lg"></i>
                            </button>

                            <a href="/products/blink-home-security-camera-system"
                                class="js_quick_view button-quick-view">
                                <i class="bi bi-eye"></i>

                                <span class="text absolute">{{ messages.messages.shop.quickView }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Box Two -->
            <div class="product-collection__content">
                <div class="product-collection__title">
                    <h3 class="text-center">
                        <base-button link :to="getProductDetails" class="alo-text-transform">
                            {{ brand }} {{ desc }}</base-button>
                    </h3>
                </div>
                <!-- Start of Judge.me code -->
                <div class="jdgm-widget jdgm-preview-badge text-center mb-2">
                    <div class="jdgm-prev-badge">
                        <span class="jdgm-prev-badge__stars" tabindex="0" aria-label="5.00 stars" role="button">
                            <i class="bi bi-star-fill"></i>
                            <i class="bi bi-star-fill"></i>
                            <i class="bi bi-star-fill"></i>
                            <i class="bi bi-star-half"></i>
                            <i class="bi bi-star"></i>
                        </span>
                        <span class="jdgm-prev-badge__text">(1 review)</span>
                    </div>
                </div>
                <!-- End of Judge.me code -->
                <div class="frm-price-color mb-2">
                    <div class="product-collection__price text-center">
                        <div class="price price--sale d-flex justify-content-center algin-items-center flex-row">
                            <div class="current_price">{{ price }}TL</div>
                            <div class="compare" v-if="old_price">{{ old_price }}TL</div>
                        </div>
                    </div>
                </div>
                <div class="button-cart-bottom">
                    <div class="flex alo-gap">
                        <div class="button-container">
                            <!-- :style="{ backgroundColor: isLoading || is_added_to_cart ? '#aaa' : '#222' }" -->
                            <button type="submit" @click="addToCart" :disabled="isShowLoader"
                                class="add-to-cart js_add_to_cart_button">
                                <div class="text">{{ messages.messages.shop.addToCart }}</div>

                                <div class="main_loader_position position-absolute">
                                    <base-loader :show="isShowLoader" :is_ok_sended="is_ok_sended"></base-loader>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- End Product Cart -->
</template>

<script>
import messageEn from '../../locales/en-US.json';
import messageAr from '../../locales/ar-AR.json';
import messageTr from '../../locales/tr-TR.json';
import EventBus from '../../event-bus'; // Import the event bus


export default {
    data() {
        return {
            oldAndNew: null,
            is_old_price: false,
            heart_outline: 'heart-outline',
            heart: 'heart',
            is_opend: false,
            hovered: false,
            qty: 1,
            isShowLoader: null,
            is_ok_sended: false,
            // firstImage: `${this.rootUrl}` + this.image[0].attributes.formats.small.url,
            // secondImage: `${this.rootUrl}${this.image[1].attributes.formats.small.url}`,
            addedClass: false,
            addedClassCompare: false,
            currentLanguage: localStorage.getItem('language') || 'en',
            messages: this.getMessages(localStorage.getItem('language') || 'en'),
            // rootUrl: process.env.VUE_APP_URL

        };
    },
    props: {
        id: { typeof: Number.toString(), required: true },
        brand: { typeof: String },
        title: { typeof: String },
        display_card: { typeof: String },
        price: { typeof: Number },
        ssd_capacity: { typeof: String },
        ram: { typeof: String },
        image: { typeof: Object },
        desc: { typeof: String },
        old_price: { typeof: Number },
        is_new: { typeof: Boolean },
        quantity: { typeof: Number, },
    },
    computed: {
        selectedProduct() {
            return this.$store.getters["product/getProducts"];
        },
        cartItems() {
            return this.$store.getters['cart/products'];
        },
        thePriceHandling() {
            let numberString = this.price.toString();
            let [integerPart, decimalPart] = numberString.split('.');
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            let formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
            return formattedNumber;
        },

        is_has_value() {
            if (this.$props.old_price) {
                return true;
            } else {
                return false;
            }
        },
        newPrice() {
            const old_price = this.$props.old_price;
            if (old_price) {
                const result1 = this.$props.old_price - this.$props.price;
                const result = result1 / this.$props.old_price;
                const result2 = result * 100;
                return Math.round(result2);
            } else {
                return old_price
            }
        },
        //-${this.brand}-${this.ssd_capacity}-${this.display_card}-${this.ram}
        getProductDetails() {
            return `/products/${this.id}`;
        }
    },
    methods: {
        async simulateAddToCart() {
            // Simulate a delay for the add-to-cart action
            return new Promise((resolve) => setTimeout(resolve, 1000));
        },
        async addToCart() {
            this.isShowLoader = true;
            this.is_ok_sended = false;

            try {
                // Perform the add-to-cart action (e.g., call API)
                await this.$store.dispatch("cart/addToCart", { id: this.id, qty: this.qty });

                // Simulate add-to-cart action (remove this if actual action is done)

                // Set loader to false and checkmark to true after adding
                this.isShowLoader = false;
                this.is_ok_sended = true; // Show the check icon


                // Reset the checkmark after 2 seconds
                setTimeout(() => {
                    this.is_ok_sended = false; // Hide check icon
                }, 2000);

            } catch (error) {
                console.error("Error adding to cart:", error);

            } finally {
                // this.isLoading = false; // Disable loading state
            }
        },
        addProductToWishlist() {
            this.$store.dispatch('wishlist/addToWishlist', this.id)
            // this.addedClass = !this.addedClass;

            // Retrieve existing products from localStorage
            const existingProducts = JSON.parse(localStorage.getItem('lastVisitedProducts')) || [];

            // Add current product to the array
            const productData = {
                id: this.id,
            };
            // Avoid duplicate entries based on product ID
            const updatedProducts = existingProducts.filter(p => p !== this.id);
            updatedProducts.push(productData);
            // Save the updated array back to localStorage
            localStorage.setItem('lastVisitedProducts', JSON.stringify(updatedProducts));
        },

        removeProductFromWishlist() {
            this.$store.dispatch("wishlist/removeFromWishlist", {
                id: this.id,
            });
            this.addedClass = !this.addedClass;
        },
        getMessages(language) {
            switch (language) {
                case 'en':
                    return messageEn;
                case 'ar':
                    return messageAr;
                case 'tr':
                    return messageTr;
                default:
                    return messageEn;
            }
        }
    },
    created() {
        // Listen for language change events
        EventBus.on('language-changed', (newLanguage) => {
            this.currentLanguage = newLanguage;
            this.messages = this.getMessages(newLanguage);
        });
    },
    beforeUnmount() {
        // Clean up the event listener
        EventBus.off('language-changed');
    }
}
</script>

<style scoped>
.button-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
        box-shadow: 0 -1px 0 #000;
    }

    50% {
        transform: rotate(180deg);
        box-shadow: 0 -1px 0 #000;
    }

    100% {
        transform: rotate(360deg);
        box-shadow: 0 -1px 0 #000;
    }
}

@media screen and (max-width: 464px) {
    .product-collection {
        height: 325px;
    }

    .media {
        height: 100px;
    }
}

.product-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    transition: box-shadow 0.3s ease;
}

.product-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
}

.product-title {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
}

.product-price {
    font-size: 1em;
    color: #007bff;
}
</style>