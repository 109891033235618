import axios from "axios";
export default {
  namespaced: true,
  state() {
    return {
      data: [],
      ProductDetails: [],
      newData: [],
      offerData: [],
      lastVisitedProduct: [], // For storing the last visited product
    };
  },
  mutations: {
    mutationProducts(state, payload) {
      state.data = payload;
      sessionStorage.setItem("productData", JSON.stringify(payload));
    },
    setLastVisitedProduct(state, product) {
      state.lastVisitedProduct = product;
      // sessionStorage.setItem("lastVisitedProduct", JSON.stringify(product));
    },
    mutationDetailsProducts(state, payload) {
      state.ProductDetails = payload;
      // localStorage.setItem("lastVisitedProduct", JSON.stringify(payload));
    },
    mutationSearchProducts(state, payload) {
      state.data = payload;
      sessionStorage.setItem("productData", JSON.stringify(payload));
    },
    mutationIs_newProducts(state, payload) {
      state.newData = payload;
      sessionStorage.setItem("newData", JSON.stringify(payload));
    },
    mutationOfferProducts(state, payload) {
      state.offerData = payload;
      sessionStorage.setItem("offerData", JSON.stringify(payload));
    },
  },
  actions: {
    async getQueryDetailsProduct(context, foundProduct) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API +
            `/products?populate=*&filters[id][$eq]=${foundProduct}`,
          {
            headers: {
              Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
            },
          }
        );
        const responseData = response.data;
        await context.commit("mutationDetailsProducts", responseData);
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async actionLastVisitedProduct(context, foundProducts) {
      const productIds = foundProducts.map((product) => product.id);
      // Create the query with the format filters[id][$in][0]=1&filters[id][$in][1]=2&...
      const filterQuery = productIds
        .map((id, index) => `filters[id][$in][${index}]=${id}`)
        .join("&");
      try {
        const response = await axios.get(
          process.env.VUE_APP_API +
            `/products?populate=*&${filterQuery}`,
          {
            headers: {
              Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
            },
          }
        );
        const responseData = response.data;

        await context.commit("setLastVisitedProduct", responseData);
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async getSearchProduct(context, foundProduct) {
      const lowerCaseFoundProduct = foundProduct.toLowerCase();
      const encodedProduct = encodeURIComponent(lowerCaseFoundProduct);

      // Construct the base URL for the product search
      let baseURL = `${process.env.VUE_APP_API}/products?populate=*`;

      baseURL += `&filters[$or][0][title][$containsi]=${encodedProduct}`;
      baseURL += `&filters[$or][1][category][title][$containsi]=${encodedProduct}`;
      baseURL += `&filters[$or][2][brand][title][$containsi]=${encodedProduct}`;

      try {
        // Fetch the products
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
          },
        });
        const responseData = response.data;
        await context.commit("mutationSearchProducts", responseData);
        // Return whether any products were found
      } catch (error) {
        // Handle errors here
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          console.log(error.request);
          throw new Error("No response received");
        } else {
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async actionProducts(
      context,
      { pageNumber = 1, sort = "", filters = {} } = {}
    ) {
      pageNumber = isNaN(pageNumber) ? 1 : pageNumber;

      let url =
        process.env.VUE_APP_API +
        `/products?populate=*&pagination[page]=${pageNumber}&pagination[pageSize]=20&sort=${sort}`;

      if (filters.categories && filters.categories.length) {
        filters.categories.forEach((category) => {
          url += `&filters[category][title][$eq]=${category}`;
        });
      }
      if (filters.brands && filters.brands.length) {
        filters.brands.forEach((brand) => {
          url += `&filters[brand][title][$eq]=${brand}`;
        });
      }
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
          },
        });
        const responseData = response.data;

        await context.commit("mutationProducts", responseData);
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async loadNewProduct(context) {
      let url =
        process.env.VUE_APP_API +
        `/products?populate=*&filters[is_new][$eq]=true`;

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
          },
        });
        const responseData = response.data;

        await context.commit("mutationIs_newProducts", responseData);
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async offerDataProducts(context) {
      let url =
        process.env.VUE_APP_API +
        `/products?populate=*&filters[offer][$eq]=true`;

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
          },
        });
        const responseData = response.data;

        await context.commit("mutationOfferProducts", responseData);
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
  },
  getters: {
    getProducts(state) {
      return state.data;
    },
    getProductDetails(state) {
      return state.ProductDetails;
    },
    getLastVisitedProduct(state) {
      return state.lastVisitedProduct;
    },
    getSearchProduct(state) {
      return state.data;
    },
    newProducts(state) {
      return state.newData;
    },
    offerProducts(state) {
      return state.offerData;
    },
  },
};
